$main-bg-color: #3f434b;
$primary-color: #5CC75C;

$error-color: #e50307;
$warning-color: #f6872b;
$info-color: #64748B;

$link-color: #257d90;
$section-bg: #afb1b2;

$txt-dark-color: #3f434b;
$txt-light-color: white;

$one: 550px;
$two: 680px;
$three: 800px;
$four: 920px;

$sidebar-width: 240px;