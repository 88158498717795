// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.
@use '/src/variables.scss';
@import "/src/variables.scss";

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/


$ee-primary-palette: (
   50 : #ebf8eb,
   100 : #ceeece,
   200 : #aee3ae,
   300 : #8dd88d,
   400 : #74cf74,
   500 : #5cc75c,
   600 : #54c154,
   700 : #4aba4a,
   800 : #41b341,
   900 : #30a630,
   A100 : #ecffec,
   A200 : #b9ffb9,
   A400 : #86ff86,
   A700 : #6dff6d,
   contrast: (50 : #000000,
      100 : #000000,
      200 : #000000,
      300 : #000000,
      400 : #000000,
      500 : #000000,
      600 : #000000,
      700 : #000000,
      800 : #000000,
      900 : #ffffff,
      A100 : #000000,
      A200 : #000000,
      A400 : #000000,
      A700 : #000000,
   )
);

$ee-accent-palette: (
   50 : #ebf2f8,
   100 : #cedeee,
   200 : #aec9e3,
   300 : #8db3d8,
   400 : #74a2cf,
   500 : #5c92c7,
   600 : #548ac1,
   700 : #4a7fba,
   800 : #4175b3,
   900 : #3063a6,
   A100 : #ecf4ff,
   A200 : #b9d6ff,
   A400 : #86b8ff,
   A700 : #6da9ff,
   contrast: (50 : #000000,
      100 : #000000,
      200 : #000000,
      300 : #000000,
      400 : #000000,
      500 : #000000,
      600 : #000000,
      700 : #ffffff,
      800 : #ffffff,
      900 : #ffffff,
      A100 : #000000,
      A200 : #000000,
      A400 : #000000,
      A700 : #000000,
   )
);

// The warn palette is optional (defaults to red).

// Create the theme object. A theme consists of configurations for individual
$my-primary: mat.define-palette($ee-primary-palette, 500);
$my-accent: mat.define-palette($ee-accent-palette, 500);
$my-warn: mat.define-palette(mat.$red-palette);
// theming systems such as "color" or "typography".
$ee-setup-theme: mat.define-light-theme((color: (primary: $my-primary,
            accent: $my-accent,
            warn: $my-warn,
         )));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($ee-setup-theme);

*,
::before,
::after {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
}

.spacer {
   display: flex;
   flex: 1 1 auto;
}

fieldset {
   padding: 20px;
   display: flex;
   flex-direction: column;
   gap: 12px;

   legend {
      padding-inline: 8px;
   }
}

html,
body {
   height: 100%;
}

body {
   margin: 0;
   font-family: Roboto, "Helvetica Neue", sans-serif;
}

mat-form-field {
   display: flex;
   flex: 1;
}

//? Custom width of mat-menu 
.custom.mat-mdc-menu-panel {
   min-width: 120px !important;
   max-width: 300px !important;
}

.list-header {
   padding-inline: 16px;
   display: flex !important;
   gap: 8px;

   background: #d1d1d1;

   span {
      flex: 1;
   }
}

.data-section {
   font-size: large;
   padding: 8px 8px;
   border-bottom: 2px solid #424242;
   color: #424242;
   font-weight: 500;

   margin-bottom: 16px;
}

//list design
.inv-list {

   // box-sizing: border-box;
   @media (min-width: 1025px) {
      margin: 24px;
      box-shadow: 0 0 14px 4px rgba($color: #091e42, $alpha: 0.15);
      border-radius: 4px;
   }

   padding-top: 0 !important;
   overflow: hidden;

   .font-w-500 {
      font-weight: 500;
   }
}


.inv-list:empty {
   display: none;
}

.inv-list small {
   font-size: 9pt;
   opacity: 0.6;
}

.inv-list .mat-mdc-list-item.deleted {
   border-left: 2px solid red;
   color: rgb(175, 175, 175);
}

.info-color {
   color: variables.$info-color;
}

//? action table
.inv-table {

   // box-sizing: border-box;
   @media (min-width: 1025px) {
      margin: 24px;
      box-shadow: 0 0 14px 4px rgba($color: #091e42, $alpha: 0.15);
      border-radius: 4px;
   }

   padding-top: 0 !important;
   overflow: hidden;

   .font-w-500 {
      font-weight: 500;
      color: $txt-dark-color;
   }

   small {
      line-height: 16px;
   }
}

mat-action-list>h3 {
   font-weight: 600 !important;
   margin: 12px 16px !important;
   display: flex;
   flex-direction: row;
   gap: 16px;
   color: variables.$txt-dark-color !important;
}

.list-items-container {
   display: flex;
   gap: 16px;

   >.col {

      &>small,
      &>span {
         white-space: nowrap;
         text-overflow: ellipsis;
         overflow: hidden;
      }
   }
}


.header-blank {
   min-width: 48px;
}

.actions {
   display: flex;
   flex: 0 1 48px;

   align-self: flex-end;
}

mat-action-list.mat-mdc-action-list {
   padding: 0;
}

//? multiline option
mat-option {
   div {
      display: flex;
      flex-direction: column;
   }

   small {
      line-height: 10px;
      color: variables.$info-color;
   }
}

//?multiline options end


// scrollbar
::-webkit-scrollbar {
   width: 5px;
   height: 5px;
}

::-webkit-scrollbar-track {
   background: #555;
}

::-webkit-scrollbar-thumb {
   border-radius: 10px;
   background: #888;
}

::-webkit-scrollbar-thumb:is(:hover) {
   background: #9c9c9c;
}

.warn-color {
   color: variables.$warning-color;
}

//!BDD LIBRARY

.col {
   display: flex;
   flex: 0 1 400px;
   flex-direction: column;
   justify-content: center;

   text-overflow: ellipsis;
   white-space: nowrap;
   overflow: hidden;
}

.col {
   @media (width < variables.$four) {
      &.four {
         width: variables.$four;
         display: none;
      }
   }

   @media (width < variables.$three) {
      &.three {
         display: none;
      }
   }

   @media (width < variables.$two) {
      &.two {
         display: none;
      }
   }

   @media (width < variables.$one) {
      &.one {
         display: none;
      }
   }
}

//

//mat-menu
.mat-mdc-menu-content {
   padding: 8px 8px !important;
}

.mat-mdc-menu-item {
   min-height: 64px;

   span,
   mat-icon {
      color: variables.$txt-dark-color !important;
   }
}

.mat-mdc-menu-item:is(:hover) {
   background-color: variables.$primary-color !important;
   border-radius: 4px;

   span,
   mat-icon {
      color: variables.$txt-light-color !important;
   }
}

//mat-dialog
.cusom-dialog {
   .mat-mdc-dialog-container {
      position: relative;
   }

   h2 {
      background: #32363f;
      color: white !important;
      text-align: center;
      font-size: 12pt !important;
      font-weight: 100;
      padding-inline: 12px;
      padding-block: 18px;
      margin-block: -24px !important;
   }

   .dialog-close-btn {
      position: absolute;
      top: 8px;
      right: 8px;
      color: white;
      z-index: 10;
   }


   .dialog-content {
      margin-top: 40px;
   }
}

.field-wrappers {
   display: flex;
   gap: 8px;
}

//! BDD LIBRARY FOR LAYOUTING

//? VARIABLES

$lib: 'bdd-';
//Break points values
$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$xxl: 1536px;

//Break points names
$break-points: (
   xxl: $xxl,
   xl: $xl,
   lg: $lg,
   md: $md,
   sm: $sm,
   // none:null
);

//Padding and margins consts
$padding-margin-sizes: 0, 1, 2, 3, 4, 5;
$padding-margin-const: 8px;

$flex-sizes: 0, 1, 2, 3, 4, 5;

.#{$lib}mt-a {
   margin-top: auto;
}

.#{$lib}mb-a {
   margin-bottom: auto;
}

.#{$lib}ml-a {
   margin-left: auto;
}

.#{$lib}mr-a {
   margin-right: auto;
}

.#{$lib}m-a {
   margin: auto;
}

.#{$lib}my-a {
   margin-block: auto;
}

.#{$lib}mx-a {
   margin-inline: auto;
}

//Padding and Margins
@each $item,
$value in $break-points {
   @each $i in $padding-margin-sizes {

      // @if($item !=none) {
      $prefix: '#{$lib}#{$item}\\:';

      @media(width < $value) {

         //Padding bdd-md:px-1

         .#{$prefix}p-#{$i} {
            padding: $i * $padding-margin-const;
         }

         .#{$prefix}px-#{$i} {
            padding-inline: $i * $padding-margin-const;
         }

         .#{$prefix}py-#{$i} {
            padding-block: $i * $padding-margin-const;
         }

         .#{$prefix}pt-#{$i} {
            padding-top: $i * $padding-margin-const;
         }

         .#{$prefix}pb-#{$i} {
            padding-bottom: $i * $padding-margin-const;
         }

         .#{$prefix}pl-#{$i} {
            padding-left: $i * $padding-margin-const;
         }

         .#{$prefix}pr-#{$i} {
            padding-right: $i * $padding-margin-const;
         }

         //margins
         .#{$prefix}m-#{$i} {
            margin: $i * $padding-margin-const;
         }

         .#{$prefix}mx-#{$i} {
            margin-inline: $i * $padding-margin-const;
         }

         .#{$prefix}my-#{$i} {
            margin-block: $i * $padding-margin-const;
         }

         .#{$prefix}mt-#{$i} {
            margin-top: $i * $padding-margin-const;
         }

         .#{$prefix}mb-#{$i} {
            margin-bottom: $i * $padding-margin-const;
         }

         .#{$prefix}ml-#{$i} {
            margin-left: $i * $padding-margin-const;
         }

         .#{$prefix}mr-#{$i} {
            margin-right: $i * $padding-margin-const;
         }
      }

      // }
   }
}

//clapm padding and margins, min width is 8px, starting max value is 16px (p-2)
//size will clamp on each 400px. That mean, the margins will be calculated and increased between min and max value on each 400px, and will be increased for 8px
@each $i in $padding-margin-sizes {
   $value: $i * $padding-margin-const;

   //padding
   .#{$lib}p-#{$i} {
      padding: $value;
   }

   .#{$lib}px-#{$i} {
      padding-inline: $value;
   }

   .#{$lib}py-#{$i} {
      padding-block: $value;
   }

   .#{$lib}pt-#{$i} {
      padding-top: $value;
   }

   .#{$lib}pb-#{$i} {
      padding-bottom: $value;
   }

   .#{$lib}pl-#{$i} {
      padding-left: $value;
   }

   .#{$lib}pr-#{$i} {
      padding-right: $value;
   }

   //margins
   .#{$lib}m-#{$i} {
      margin: $value;
   }

   .#{$lib}mx-#{$i} {
      margin-inline: $value;
   }

   .#{$lib}my-#{$i} {
      margin-block: $value;
   }

   .#{$lib}mt-#{$i} {
      margin-top: $value;
   }

   .#{$lib}mb-#{$i} {
      margin-bottom: $value;
   }

   .#{$lib}ml-#{$i} {
      margin-left: $value;
   }

   .#{$lib}mr-#{$i} {
      margin-right: $value;
   }

   //clamp padding and margins
   @if $i >1 {
      .#{$lib}p-#{$i}-max {
         padding: clamp(8px, 2vw, $value);
      }

      .#{$lib}m-#{$i}-max {
         margin: clamp(8px, 2vw, $value);
      }
   }
}

//show and hide classes
.#{$lib}show {
   display: block;
}

.#{$lib}hide {
   display: none;
}

@each $item,
$value in $break-points {
   @media(width < $value) {

      .#{$lib}#{$item}\:show {
         display: block;
      }

      .#{$lib}#{$item}\:hide {
         display: none;
      }
   }
}

//?FLEX

.#{$lib}flex {
   display: flex;
}

.#{$lib}no-flex {
   flex: none;
}

.#{$lib}flex-row {
   flex-direction: row;
}

.#{$lib}flex-row-r {
   flex-direction: row-reverse;
}

.#{$lib}flex-col {
   flex-direction: column;
}

.#{$lib}flex-col-r {
   flex-direction: column-reverse;
}

.#{$lib}flex-wrap {
   flex-wrap: wrap;
}

.#{$lib}flex-nowrap {
   flex-wrap: nowrap;
}

.#{$lib}flex-1 {
   flex: 1;
}

//Justify content
.#{$lib}jc-start {
   justify-content: flex-start;
}

.#{$lib}jc-end {
   justify-content: flex-end;
}

.#{$lib}jc-center {
   justify-content: center;
}

.#{$lib}jc-space-between {
   justify-content: space-between;
}

.#{$lib}jc-space-around {
   justify-content: space-around;
}

.#{$lib}jc-space-evenly {
   justify-content: space-evenly;
}

//Align content
.#{$lib}ai-stretch {
   align-items: stretch;
}

.#{$lib}ai-start {
   align-items: flex-start;
}

.#{$lib}ai-end {
   align-items: flex-end;
}

.#{$lib}ai-center {
   align-items: center;
}

.#{$lib}ai-baseline {
   align-items: baseline;
}

//Align self
.#{$lib}as-auto {
   align-self: auto;
}

.#{$lib}as-stretch {
   align-self: stretch;
}

.#{$lib}as-center {
   align-self: center;
}

.#{$lib}as-start {
   align-self: flex-start;
}

.#{$lib}as-end {
   align-self: flex-end;
}

.#{$lib}as-baseline {
   align-self: baseline;
}

@each $i in $flex-sizes {
   .#{$lib}flex-#{$i} {
      display: flex;
      flex: $i;
   }

   .#{$lib}flex-b-#{$i*100} {
      flex-basis: $i * 100px;
   }

   .#{$lib}gap-#{$i} {
      gap: $i * $padding-margin-const;
   }
}


@each $item,
$value in $break-points {
   @each $i in $flex-sizes {
      $prefix: '#{$lib}#{$item}\\:';

      @media(width < $value) {

         .#{$prefix}flex-#{$i} {
            flex: $i;
         }

         .#{$prefix}gap-#{$i} {
            gap: $i * $padding-margin-const;
         }

         .#{$prefix}flex-b-#{$i*100} {
            flex-basis: $i * 100px;
         }
      }
   }
}

@each $item,
$value in $break-points {
   $prefix: '#{$lib}#{$item}\\:';

   @media(width < $value) {

      .#{$prefix}no-flex {
         flex: none;
      }

      .#{$prefix}flex-row {
         flex-direction: row;
      }

      .#{$prefix}flex-row-r {
         flex-direction: row-reverse;
      }

      .#{$prefix}flex-col {
         flex-direction: column;
      }

      .#{$prefix}flex-col-r {
         flex-direction: column-reverse;
      }


      //Justify content
      .#{$prefix}jc-start {
         justify-content: flex-start;
      }

      .#{$prefix}jc-end {
         justify-content: flex-end;
      }

      .#{$prefix}jc-center {
         justify-content: center;
      }

      .#{$prefix}jc-space-between {
         justify-content: space-between;
      }

      .#{$prefix}jc-space-around {
         justify-content: space-around;
      }

      .#{$prefix}jc-space-evenly {
         justify-content: space-evenly;
      }

      //Align content
      .#{$prefix}ai-stretch {
         align-items: stretch;
      }

      .#{$prefix}ai-start {
         align-items: flex-start;
      }

      .#{$prefix}ai-end {
         align-items: flex-end;
      }

      .#{$prefix}ai-center {
         align-items: center;
      }

      .#{$prefix}ai-baseline {
         align-items: baseline;
      }

      //Align self

      .#{$prefix}as-auto {
         align-self: auto;
      }

      .#{$prefix}as-stretch {
         align-self: stretch;
      }

      .#{$prefix}as-center {
         align-self: center;
      }

      .#{$prefix}as-start {
         align-self: flex-start;
      }

      .#{$prefix}as-end {
         align-self: flex-end;
      }

      .#{$prefix}as-baseline {
         align-self: baseline;
      }
   }
}